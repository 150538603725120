import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";

const Imprint: React.FC = () => {
  return (
    <>
      <SEO title={"Privacy Policy"} description={"Privacy Policy"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Imprint</h1>
          <p>
            FASTRRR International Kft. (LIMITED) is the responsible company for
            this website.
          </p>
          <InfoBlock>
            <div>
              <h2>Address</h2>
              <p>
                FASTRRR International Kft.
                <br />
                Hungary, Veszprém
                <br />
                8200
                <br />
                Bolgár Mihály utca 24.
              </p>
              <h2>Email</h2>
              <p>info@fastrrr.com</p>
              <h2>Registration No.</h2>
              <p>19-09-520671</p>
              <h2>VAT ID No.</h2>
              <p>27034712-2-19</p>
              <h2>Website last build:</h2>
              <p>2023-05-31 22:10:31</p>
            </div>
            <Card>
              <h2>Have a question?</h2>
              <p>
                Email us: <span>info@fastrrr.com</span>
              </p>
            </Card>
          </InfoBlock>
          <h2>Google Analytics</h2>
          <p>
            This website uses Google Analytics, a web analytics service provided
            by Google, Inc. (“Google”). Google Analytics uses “cookies”, which
            are text files placed on your computer, to help the website analyze
            how users use the site. The information generated by the cookie
            about your use of the website will be transmitted to and stored by
            Google on servers in the United States . In case IP-anonymisation is
            activated on this website, your IP address will be truncated within
            the area of Member States of the European Union or other parties to
            the Agreement on the European Economic Area. Only in exceptional
            cases the whole IP address will be first transfered to a Google
            server in the USA and truncated there. The IP-anonymisation is
            active on this website. Google will use this information on behalf
            of the operator of this website for the purpose of evaluating your
            use of the website, compiling reports on website activity for
            website operators and providing them other services relating to
            website activity and internet usage. The IP-address, that your
            Browser conveys within the scope of Google Analytics, will not be
            associated with any other data held by Google. You may refuse the
            use of cookies by selecting the appropriate settings on your
            browser, however please note that if you do this you may not be able
            to use the full functionality of this website. You can also opt-out
            from being tracked by Google Analytics with effect for the future by
            downloading and installing Google Analytics Opt-out Browser Addon
            for your current web browser.
          </p>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Imprint;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
  h2 {
    font-size: min(
      max(2.4rem, calc(2.4rem + ((1vw - 0.48rem) * 0.8333))),
      3rem
    );
    min-height: 0vw;
    margin-top: 50px;
  }
  p,
  li {
    line-height: min(
      max(2.8rem, calc(2.8rem + ((1vw - 0.48rem) * 1.1111))),
      3.6rem
    );
    min-height: 0vw;
  }
`;

const InfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  width: 500px;
  height: 200px;
  margin-top: 64px;
  background: #ffffff;
  box-shadow: var(--shadow);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 20px 0;
  }

  span {
    text-decoration: underline;
    color: var(--color-orange);
  }

  @media (max-width: 992px) {
    width: 350px;
    height: 150px;
    h2 {
      margin: 20px 0 0 0;
    }
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;
